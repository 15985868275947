import React, {useRef} from "../../_snowpack/pkg/react.js";
import styled from "../../_snowpack/pkg/styled-components.js";
import {Chat} from "./chat.js";
import {useGame, isReady} from "./api.js";
import {Group} from "../ui/group.js";
import {Input} from "../ui/input.js";
import {Board} from "./board.js";
const GameWrapper = styled.div`
	display: flex;
	min-height: 100vh;
	justify-content: center;
	align-items: center;

	> div {
		padding: 1rem;
	}

	.row,
	.col {
		padding: 1rem;
		font-size: 1.5rem;
		font-weight: 300;
	}
`;
const Info = styled(Group)`
	input {
		background: transparent;
		color: white;
		border: none;
		font-weight: 700;
		font-size: 1.4rem;
		padding: 0;
		margin: 0;
	}

	padding: 1rem 0;
	h2 {
		margin: 0;
		margin-right: 1rem;
	}
`;
export const ChessGame = ({
  currentRoom,
  ws,
  initialGame
}) => {
  const inputref = useRef(null);
  const api = useGame(ws, currentRoom, initialGame);
  if (!currentRoom || !isReady(api))
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h1", null, "loading..."));
  return /* @__PURE__ */ React.createElement(GameWrapper, null, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Info, null, /* @__PURE__ */ React.createElement("h2", null, "Room"), /* @__PURE__ */ React.createElement(Input, {
    type: "text",
    ref: inputref,
    onFocus: () => {
      inputref?.current?.select();
      document.execCommand("copy");
    },
    onChange: (e) => e.preventDefault(),
    value: currentRoom
  })), /* @__PURE__ */ React.createElement(Board, {
    api,
    lengthX: 8,
    lengthY: 8
  })), " ", /* @__PURE__ */ React.createElement(Chat, {
    api,
    currentRoom
  }));
};
