export var ServerMessageType;
(function(ServerMessageType2) {
  ServerMessageType2["Error"] = "ERROR";
  ServerMessageType2["RoomCreated"] = "ROOM_CREATED";
  ServerMessageType2["RoomChat"] = "ROOM_CHAT";
  ServerMessageType2["RoomJoin"] = "ROOM_JOIN";
  ServerMessageType2["RoomLeave"] = "ROOM_LEAVE";
  ServerMessageType2["RoomMove"] = "ROOM_MOVE";
  ServerMessageType2["RoomReady"] = "ROOM_READY";
  ServerMessageType2["JoinTeam"] = "JOIN_TEAM";
  ServerMessageType2["SyncGame"] = "SYNC_GAME";
  ServerMessageType2["StartGame"] = "START_GAME";
  ServerMessageType2["Login"] = "LOGIN";
})(ServerMessageType || (ServerMessageType = {}));
