import {openNewBackgroundTab} from "./utils.js";
import React, {useEffect, useState} from "../_snowpack/pkg/react.js";
import styled from "../_snowpack/pkg/styled-components.js";
import {ChessGame} from "./chess/index.js";
import {
  ClientMessageType,
  ServerMessageType
} from "./shared/index.js";
import {useWebsocket} from "./websocket.js";
import {Home} from "./home.js";
var Page;
(function(Page2) {
  Page2["HOME"] = "home";
  Page2["GAME"] = "game";
})(Page || (Page = {}));
const Wrapper = styled.div`
	background: linear-gradient(to right, #04131a, #181818);
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	color: white;
`;
export const App = () => {
  const [page, setPage] = useState(Page.HOME);
  const ws = useWebsocket({url: "ws://localhost:3000"});
  const [currentRoom, setCurrentRoom] = useState();
  const [initialGame, setInitialGame] = useState();
  useEffect(() => {
    const onRoomCreated = (e) => {
      const data = e.detail;
      setInitialGame(data.game);
      setCurrentRoom(data.room);
    };
    const onRoomJoined = (e) => {
      const data = e.detail;
      setCurrentRoom(data.room);
    };
    ws.on(ServerMessageType.RoomCreated, onRoomCreated);
    ws.on(ServerMessageType.RoomJoin, onRoomJoined);
    return () => {
      ws.off(ServerMessageType.RoomCreated, onRoomCreated);
      ws.off(ServerMessageType.RoomJoin, onRoomJoined);
    };
  }, [ws]);
  const startGame = () => {
    ws.send({
      type: ClientMessageType.CreateRoom
    });
    setPage(Page.GAME);
  };
  const joinRoom = (roomID) => {
    if (roomID === "dQw4w9WgXcQ" || roomID === "XhCrgZaZsrW1")
      return openNewBackgroundTab("https://www.youtube.com/watch?v=dQw4w9WgXcQ");
    ws.send({
      type: ClientMessageType.JoinRoom,
      room: roomID
    });
    setCurrentRoom(roomID);
    setPage(Page.GAME);
  };
  let currentPage;
  switch (page) {
    case Page.GAME:
      currentPage = /* @__PURE__ */ React.createElement(ChessGame, {
        currentRoom,
        ws,
        initialGame
      });
      break;
    case Page.HOME:
      currentPage = /* @__PURE__ */ React.createElement(Home, {
        startGame,
        joinRoom
      });
      break;
  }
  return /* @__PURE__ */ React.createElement(Wrapper, null, currentPage);
};
