import { a as createCommonjsModule } from '../common/_commonjsHelpers-a3307dcf.js';
import { c as createHook, R as Role_1, _ as _rollupPluginBabelHelpers8f9a8751, a as createComponent } from '../common/Role-467268c3.js';
import '../common/index-2e5f2345.js';

var Group_1 = createCommonjsModule(function (module, exports) {

Object.defineProperty(exports, '__esModule', { value: true });







// Automatically generated
var GROUP_KEYS = [];

var useGroup = createHook.createHook({
  name: "Group",
  compose: Role_1.useRole,
  keys: GROUP_KEYS,
  useProps: function useProps(_, htmlProps) {
    return _rollupPluginBabelHelpers8f9a8751._objectSpread2({
      role: "group"
    }, htmlProps);
  }
});
var Group = createComponent.createComponent({
  as: "div",
  useHook: useGroup
});

exports.Group = Group;
exports.useGroup = useGroup;
});

var Group = createCommonjsModule(function (module, exports) {

Object.defineProperty(exports, '__esModule', { value: true });










exports.Group = Group_1.Group;
exports.useGroup = Group_1.useGroup;
});

var Group$1 = Group.Group;
export { Group$1 as Group };
