import React from "../../_snowpack/pkg/react.js";
import styled from "../../_snowpack/pkg/styled-components.js";
import {Button} from "../ui/button.js";
import {Group} from "../ui/group.js";
const Controls = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	backdrop-filter: blur(4px);
	background: #1220223b;
	z-index: 10;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
const Teams = styled.div`
	display: flex;
	flex-direction: column;

	> div {
		display: flex;
	}
`;
const Team = styled.div`
	h2 {
		margin: 0;
	}

	ul {
		list-style: none;
		margin: 1rem 0;
		padding: 0;
	}

	> ${Group} {
		> button {
			flex: 1;
		}
	}

	flex: 1;
	padding: 1rem;
	margin: 0.5rem;
	border-radius: 0.4rem;
	backdrop-filter: blur(61px);
	background: #1a32369e;
`;
const renderUsers = (api, users) => users.map((user) => user === api.ws.user ? `${user} (you)` : user);
export const BoardControlls = ({api}) => /* @__PURE__ */ React.createElement(Controls, null, /* @__PURE__ */ React.createElement(Teams, null, /* @__PURE__ */ React.createElement("div", null, api.game.teams.map((team) => /* @__PURE__ */ React.createElement(Team, {
  key: team.name
}, /* @__PURE__ */ React.createElement("h2", null, team.name, " ", /* @__PURE__ */ React.createElement("span", null, team.ready ? "✅" : "❌")), /* @__PURE__ */ React.createElement("ul", null, team.users.length ? renderUsers(api, team.users).map((user) => /* @__PURE__ */ React.createElement("li", {
  key: user
}, user)) : /* @__PURE__ */ React.createElement("li", null, "no players")), /* @__PURE__ */ React.createElement(Group, null, team.users.includes(api.ws?.user ?? "") ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Button, {
  variant: "outline",
  type: "button",
  onClick: () => api.joinTeam("")
}, "Leave"), /* @__PURE__ */ React.createElement(Button, {
  variant: "primary",
  type: "button",
  onClick: api.ready
}, "Ready")) : /* @__PURE__ */ React.createElement(Button, {
  variant: "outline",
  type: "button",
  onClick: () => api.joinTeam(team.name)
}, "Join")))), /* @__PURE__ */ React.createElement(Team, null, /* @__PURE__ */ React.createElement("h2", null, "No Team"), /* @__PURE__ */ React.createElement("ul", null, renderUsers(api, api.game.players.filter((player) => !api.game.teams.map((t) => t.users).flat().includes(player))).map((user) => /* @__PURE__ */ React.createElement("li", {
  key: user
}, user)))))));
