import { a as createCommonjsModule } from '../common/_commonjsHelpers-a3307dcf.js';
import '../common/index-2e5f2345.js';
import { c as createHook, a as createComponent } from '../common/Role-467268c3.js';
import { T as Tabbable_1 } from '../common/Tabbable-6f592cce.js';

var Input_1 = createCommonjsModule(function (module, exports) {

Object.defineProperty(exports, '__esModule', { value: true });


















// Automatically generated
var INPUT_KEYS = [];

var useInput = createHook.createHook({
  name: "Input",
  compose: Tabbable_1.useTabbable,
  keys: INPUT_KEYS
});
var Input = createComponent.createComponent({
  as: "input",
  memo: true,
  useHook: useInput
});

exports.Input = Input;
exports.useInput = useInput;
});

var Input = createCommonjsModule(function (module, exports) {

Object.defineProperty(exports, '__esModule', { value: true });





















exports.Input = Input_1.Input;
exports.useInput = Input_1.useInput;
});

var Input$1 = Input.Input;
export { Input$1 as Input };
