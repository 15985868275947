import {Button as Btn} from "../../_snowpack/pkg/reakit/Button.js";
import styled, {css} from "../../_snowpack/pkg/styled-components.js";
export const Button = styled(Btn)`
	font-weight: 500;

	box-sizing: border-box;
	border: 0px;
	border-radius: 4px;

	cursor: pointer;
	outline: none;
	padding: 0.5rem 0.7rem;
	font-size: 1.2rem;

	color: #000;
	background: #0ff;

	${(props) => props.variant === "secondary" && css`
			background-color: #00ff9d;
		`}

	${(props) => props.variant === "outline" && css`
			color: #0ff;
			background-color: transparent;
			box-shadow: 0px 0px 0px 2px inset;
		`}

	transform: translateY(0) scale(1);
	transition: all 0.2s ease-in-out;

	&[disabled],
	&[aria-disabled='true'] {
		cursor: auto;
		opacity: 0.5;
	}

	&:not([disabled]),
	&:not([aria-disabled='true']) {
		&:hover {
			transform: translateY(-1px) scale(1);
		}
		&:active,
		&[data-active='true'] {
		}
	}
`;
