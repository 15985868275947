import {Group as Grp} from "../../_snowpack/pkg/reakit/Group.js";
import styled from "../../_snowpack/pkg/styled-components.js";
export const Group = styled(Grp)`
	display: flex;

	> * {
		margin-right: 0.5rem;

		&:last-child {
			margin-right: 0;
		}
	}
`;
