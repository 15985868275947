import styled from "../../_snowpack/pkg/styled-components.js";
export const Select = styled.select`
	display: block;
	width: 100%;
	border-radius: 0.2rem;
	padding: 0.5em 0.75em;
	font-size: 100%;
	border: 1px solid rgba(0, 0, 0, 0.25);
	color: #4d4d4d;
	box-sizing: border-box;
	outline: none;

	&:focus {
		border-color: rgba(0, 0, 0, 0.25);
	}
`;
