export var ClientMessageType;
(function(ClientMessageType2) {
  ClientMessageType2["CreateRoom"] = "CREATE_ROOM";
  ClientMessageType2["JoinRoom"] = "JOIN_ROOM";
  ClientMessageType2["RoomChat"] = "ROOM_CHAT";
  ClientMessageType2["RoomReady"] = "ROOM_READY";
  ClientMessageType2["AttemptMove"] = "ATTEMPT_MOVE";
  ClientMessageType2["SyncGame"] = "SYNC_GAME";
  ClientMessageType2["JoinTeam"] = "JOIN_TEAM";
  ClientMessageType2["Login"] = "LOGIN";
  ClientMessageType2["Signup"] = "SIGNUP";
})(ClientMessageType || (ClientMessageType = {}));
