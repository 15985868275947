import React, {
  useEffect,
  useRef,
  useState
} from "../../_snowpack/pkg/react.js";
import styled from "../../_snowpack/pkg/styled-components.js";
import {
  ServerMessageType,
  ClientMessageType
} from "../shared/index.js";
import {Group} from "../ui/group.js";
import {Input} from "../ui/input.js";
import {Button} from "../ui/button.js";
function isMessage(message) {
  return Boolean(Object.prototype.hasOwnProperty.call(message, "type"));
}
function isChatMessage(item) {
  if (!isMessage(item))
    return false;
  return item.type === ServerMessageType.RoomChat || item.type === ServerMessageType.RoomJoin || item.type === ServerMessageType.RoomLeave || item.type === ServerMessageType.StartGame;
}
const ChatWrapper = styled.div`
	width: 20rem;
	height: 35rem;
	line-break: anywhere;
	display: flex;
	flex-direction: column;
	margin-top: 8rem;

	background: #07f9ff0a;
	border-radius: 0.5rem;

	> ul {
		flex: 1;
		overflow-y: scroll;

		list-style: none;
		padding: 0;
	}

	> div {
		display: flex;
		input {
			flex: 1;
			margin-right: 1rem;
		}
	}
`;
export const Chat = ({
  api,
  currentRoom
}) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const chatRef = useRef(null);
  const ws = api.ws;
  useEffect(() => {
    if (currentRoom !== "")
      setMessages((m) => [
        ...m,
        /* @__PURE__ */ React.createElement("li", {
          key: Date.now()
        }, "Connected to Room ", /* @__PURE__ */ React.createElement("i", null, currentRoom))
      ]);
  }, [currentRoom]);
  useEffect(() => {
    const onChat = (e) => {
      const data = e.detail;
      if (!data || !isChatMessage(data) || data.room !== currentRoom)
        return;
      setMessages((msgs) => [...msgs, data]);
      const threshold = 150;
      if (data.type !== ServerMessageType.StartGame && data.user === ws.user || chatRef.current && chatRef.current.scrollTop + chatRef.current.clientHeight > chatRef.current.scrollHeight - threshold) {
        chatRef?.current?.scrollTo?.({
          top: chatRef?.current?.scrollHeight,
          behavior: "smooth"
        });
      }
    };
    const onJoin = (e) => onChat(e);
    const onLeave = (e) => onChat(e);
    const onStart = (e) => onChat(e);
    ws.on(ServerMessageType.RoomChat, onChat);
    ws.on(ServerMessageType.RoomJoin, onJoin);
    ws.on(ServerMessageType.RoomLeave, onLeave);
    ws.on(ServerMessageType.StartGame, onStart);
    return () => {
      ws.off(ServerMessageType.RoomChat, onChat);
      ws.off(ServerMessageType.RoomJoin, onJoin);
      ws.off(ServerMessageType.RoomLeave, onLeave);
      ws.off(ServerMessageType.StartGame, onStart);
    };
  }, [currentRoom, ws]);
  const handleSendMessage = () => {
    if (message === "")
      return;
    sendMessage(message);
    setMessage("");
  };
  const sendMessage = (msg) => {
    ws.send({
      type: ClientMessageType.RoomChat,
      message: msg,
      room: currentRoom
    });
  };
  return /* @__PURE__ */ React.createElement(ChatWrapper, null, /* @__PURE__ */ React.createElement("ul", {
    ref: chatRef
  }, messages.map((msg) => {
    if (!isChatMessage(msg)) {
      return msg;
    }
    return /* @__PURE__ */ React.createElement("li", {
      key: msg.timestamp
    }, msg.type === ServerMessageType.RoomJoin && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("b", null, msg.user), " joined"), msg.type === ServerMessageType.RoomLeave && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("b", null, msg.user), " left"), msg.type === ServerMessageType.RoomChat && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("b", null, msg.user, ":"), " ", msg.message), msg.type === ServerMessageType.StartGame && /* @__PURE__ */ React.createElement("b", null, "Started Game!"));
  })), /* @__PURE__ */ React.createElement(Group, null, /* @__PURE__ */ React.createElement(Input, {
    value: message,
    onKeyDown: (e) => {
      if (e.key === "Enter")
        handleSendMessage();
    },
    onChange: (e) => setMessage(e.target.value)
  }), /* @__PURE__ */ React.createElement(Button, {
    variant: "outline",
    type: "button",
    onClick: handleSendMessage
  }, "send")));
};
