import React, {useMemo, useState} from "../../../_snowpack/pkg/react.js";
import styled, {css} from "../../../_snowpack/pkg/styled-components.js";
import {useSpring, animated} from "../../../_snowpack/pkg/react-spring.js";
import {useDrag} from "../../../_snowpack/pkg/react-use-gesture.js";
import {renderPiece} from "./render-piece.js";
const SquareWrapper = styled.td`
	&:before {
		display: block;
		content: '';
		width: 100%;
		padding-top: 100%;
	}
	width: 4rem;
	position: relative;

	> div {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		user-select: none;
		z-index: ${(props) => props.dragging ? 3 : 2};
		font-weight: 900;
		font-size: 2rem;
	}

	${(props) => props.contrast ? css`
					color: white;
					background: rgba(7, 249, 255, 0.04);
			  ` : css`
					background: #f9f9f9;
			  `}

	${(props) => props.highlighted && (props.contrast ? css`
					background: rgb(7, 94, 99);
			  ` : css`
					background: #96f9ff;
			  `)}
`;
const XPos = styled.span`
	position: absolute;
	right: 0;
	bottom: 0;
	mix-blend-mode: difference;
	color: white;
	padding: 0.1rem 0.3rem;
`;
const YPos = styled(XPos)`
	right: initial;
	bottom: initial;
	top: 0;
`;
export const Square = ({
  position,
  column,
  row,
  box,
  api
}) => {
  const tile = useMemo(() => api.getTile(position), [api, position]);
  const isHighlighted = useMemo(() => api.isHighlighted(position), [
    api,
    position
  ]);
  const [dragging, setDragging] = useState(false);
  const [{x, y}, set] = useSpring(() => ({
    x: 0,
    y: 0,
    config: {
      mass: 1,
      tension: 250,
      friction: 30
    }
  }));
  const moveable = useMemo(() => {
    const userTeam = api.game.teams.find((t) => t.users.includes(api.ws.user ?? ""));
    const tileTeam = api.game.teams.find((t) => t.name === tile?.piece?.team);
    return userTeam?.name === api.game.nextTeam && tileTeam === userTeam;
  }, [api.game.teams, api.ws.user, tile?.piece?.team, api.game.nextTeam]);
  const bind = useDrag(({down, movement: [mx, my]}) => {
    const realX = mx + box.x / 2;
    const realY = -my + box.y / 2;
    const X = Math.floor(realX / box.x);
    const Y = Math.floor(realY / box.y);
    if (dragging !== down) {
      if (!down)
        drop(X, Y);
      setDragging(down);
      api.highlightTiles(position);
    }
    void set({x: down ? mx : 0, y: down ? my : 0, immediate: down});
  }, {enabled: moveable});
  const drop = (mx, my) => {
    api.unhighlightTiles();
    if (mx === 0 && my === 0)
      return;
    const err = api.move({
      from: position,
      to: {x: position.x + mx, y: position.y + my}
    });
    if (err)
      console.error(err);
    console.log("dropped at " + String(position.x + mx) + " " + String(position.y + my));
  };
  const showYPos = position.x === 0;
  const showXPos = position.y === 0;
  return /* @__PURE__ */ React.createElement(SquareWrapper, {
    "data-position": JSON.stringify({
      x: position.x,
      y: position.y
    }),
    dragging,
    highlighted: isHighlighted,
    contrast: (column + row) % 2 === 0
  }, /* @__PURE__ */ React.createElement(animated.div, {
    ...bind(),
    style: {x, y}
  }, tile?.piece && renderPiece(tile.piece)), showXPos && /* @__PURE__ */ React.createElement(XPos, null, String.fromCharCode(97 + position.x)), showYPos && /* @__PURE__ */ React.createElement(YPos, null, String(position.y + 1)));
};
