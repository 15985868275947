import React, {useState} from "../_snowpack/pkg/react.js";
import {Button} from "./ui/button.js";
import {Input} from "./ui/input.js";
import {Select} from "./ui/select.js";
import styled from "../_snowpack/pkg/styled-components.js";
import {Group} from "./ui/group.js";
const HomeWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100vh;

	> h1 > span {
		filter: drop-shadow(rgba(0, 252, 210, 0.3) 0px 0px 4px);
		color: cyan;
	}

	> div {
		display: flex;

		> div {
			width: 15rem;
			text-align: center;

			margin: 0 2rem;
			display: flex;
			flex-direction: column;

			margin: 0.5rem;
		}
	}
`;
export const Home = ({
  startGame,
  joinRoom
}) => {
  const [gamemode, setGamemode] = useState("Chess");
  const [joinRoomValue, setJoinRoomValue] = useState("");
  return /* @__PURE__ */ React.createElement(HomeWrapper, null, /* @__PURE__ */ React.createElement("h1", null, "Welcome to ", /* @__PURE__ */ React.createElement("span", null, "Schess"), "!"), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h2", null, "Create Game"), /* @__PURE__ */ React.createElement(Group, null, /* @__PURE__ */ React.createElement(Select, {
    value: gamemode,
    onChange: (e) => {
      setGamemode(e.target.value);
    }
  }, /* @__PURE__ */ React.createElement("option", {
    value: "Chess"
  }, "Chess"), /* @__PURE__ */ React.createElement("option", {
    value: "Rundlauf"
  }, "Rundlauf")), /* @__PURE__ */ React.createElement(Button, {
    type: "button",
    onClick: () => startGame(gamemode)
  }, "play"))), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h2", null, "Join"), /* @__PURE__ */ React.createElement(Group, null, /* @__PURE__ */ React.createElement(Input, {
    value: joinRoomValue,
    onChange: (e) => setJoinRoomValue(e.target.value),
    placeholder: "Room-ID..."
  }), /* @__PURE__ */ React.createElement(Button, {
    type: "button",
    onClick: () => joinRoom(joinRoomValue)
  }, "join")))));
};
