import React from "../../../_snowpack/pkg/react.js";
import styled from "../../../_snowpack/pkg/styled-components.js";
const Piece = styled.span`
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
		1px 1px 0 #000;
`;
export const renderPiece = (piece) => {
  const pieces = {
    king: ["♔", "♚"],
    queen: ["♕", "♛"],
    rook: ["♖", "♜"],
    bishop: ["♗", "♝"],
    knight: ["♘", "♞"],
    pawn: ["♙", "♟"]
  };
  const symbol = pieces?.[piece.name]?.[Number(piece.team === "white")] || "";
  return /* @__PURE__ */ React.createElement(Piece, {
    "data-team": piece.team,
    style: {
      color: piece.team === "white" ? "white" : "black",
      textShadow: piece.team === "white" ? `-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
		1px 1px 0 #000` : `-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
		1px 1px 0 #fff`
    }
  }, symbol);
};
