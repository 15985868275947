import {useCallback, useEffect, useRef, useState} from "../_snowpack/pkg/react.js";
import {
  ClientMessageType,
  ServerMessageType
} from "./shared/index.js";
var WSEvents;
(function(WSEvents2) {
  WSEvents2["Invalid"] = "INVALID";
  WSEvents2["Error"] = "ERROR";
})(WSEvents || (WSEvents = {}));
class WSEvent extends CustomEvent {
  constructor(message) {
    super(message.type, {detail: message});
  }
}
export const useWebsocket = ({
  url = document.location.host.includes("localhost") ? "ws://169.69.69.73:3000" : "schess-server.explodingcamera.com"
}) => {
  const [socket] = useState(() => new WebSocket(url));
  const [eventEmmitter] = useState(new EventTarget());
  const [user, setUser] = useState();
  const [connected, setConnected] = useState(false);
  const [error, setError] = useState(null);
  const keepAliveInterval = useRef();
  const disconnect = useCallback(() => {
    socket?.close();
    setConnected(false);
    clearInterval(keepAliveInterval.current);
  }, [socket]);
  useEffect(() => {
    const connectToWs = () => {
      socket.onopen = () => {
        console.info("Connected to WebSocket: " + url);
        ws.send({
          type: ClientMessageType.Login
        });
        keepAliveInterval.current = window.setInterval(() => {
          socket.send("ping");
        }, 1e3);
        setConnected(true);
      };
      socket.onerror = (err) => {
        console.error("WebSocket Error: ", err);
        eventEmmitter.dispatchEvent(new WSEvent({
          type: WSEvents.Error
        }));
      };
      socket.onclose = (e) => {
        console.error("Closed Websocket Connection: ", e);
        clearInterval(keepAliveInterval.current);
      };
      socket.onmessage = (message) => {
        if (message.data === "")
          return;
        let msg;
        try {
          msg = JSON.parse(message.data);
        } catch (error2) {
          console.error("Error parsing Websocket Message: ", error2);
          return;
        }
        if (msg.type === ServerMessageType.Login) {
          setUser(msg.user);
          return;
        }
        if (msg.type === ServerMessageType.Error) {
          console.error("ERROR:", msg);
        }
        eventEmmitter.dispatchEvent(new WSEvent({...msg}));
      };
    };
    connectToWs();
    return disconnect;
  }, [url, disconnect, eventEmmitter, socket]);
  const ws = {
    socket,
    connected,
    error,
    disconnect,
    user,
    send: (message) => {
      socket.send(JSON.stringify(message));
    },
    on: (type, handler) => {
      eventEmmitter.addEventListener(type, handler);
    },
    off: (type, handler) => {
      eventEmmitter.removeEventListener(type, handler);
    }
  };
  return ws;
};
