import React from "../../_snowpack/pkg/react.js";
import styled from "../../_snowpack/pkg/styled-components.js";
import useResizeObserver from "../../_snowpack/pkg/use-resize-observer.js";
import {Square} from "./square/index.js";
import {BoardControlls} from "./controls.js";
const BoardWrapper = styled.div`
	position: relative;

	border-radius: 0.5rem;
	overflow: hidden;

	> table {
		/* add 0px blur, otherwise the backdropfilter of the controls dont work */
		filter: blur(0px);

		border-collapse: collapse;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	}
`;
export const Board = ({
  lengthX,
  lengthY,
  api
}) => {
  const {ref, width = 1, height = 1} = useResizeObserver();
  const boxWidth = width / lengthX;
  const boxHeight = height / lengthY;
  return /* @__PURE__ */ React.createElement(BoardWrapper, {
    ref
  }, !api.game.running && /* @__PURE__ */ React.createElement(BoardControlls, {
    api
  }), /* @__PURE__ */ React.createElement("table", null, /* @__PURE__ */ React.createElement("tbody", null, Array(lengthY).fill(0).map((_, row) => /* @__PURE__ */ React.createElement("tr", {
    key: row
  }, Array(lengthX).fill(0).map((_2, column) => /* @__PURE__ */ React.createElement(Square, {
    key: `${column}-${row}`,
    column,
    row,
    position: {x: column, y: lengthY - row - 1},
    box: {x: boxWidth, y: boxHeight},
    api
  })))))));
};
