import {useEffect, useState, useCallback} from "../../_snowpack/pkg/react.js";
import {
  ServerMessageType,
  ClientMessageType
} from "../shared/index.js";
import deepEqual from "../../_snowpack/pkg/deep-equal.js";
export const useGame = (ws, currentRoom, initialGame) => {
  const [game, setGame] = useState(initialGame);
  const [room, setRoom] = useState(currentRoom);
  const [highlightedTiles, setHighlightedTiles] = useState([]);
  const [lastMove, setLastMove] = useState();
  useEffect(() => {
    setGame(initialGame);
    console.log("reset game to initial game");
  }, [initialGame]);
  const unhighlightTiles = () => setHighlightedTiles([]);
  const isHighlighted = (pos) => Boolean(highlightedTiles?.find((tile) => deepEqual(tile, pos)));
  const highlightTiles = (origin) => setHighlightedTiles(game?.nextMoves?.filter((move2) => deepEqual(move2.from, origin)).map((move2) => move2.to) ?? []);
  const getTile = useCallback((pos) => game?.board?.tiles?.[pos.x]?.[game.board.height - 1 - pos.y], [game]);
  const setTile = useCallback((pos, tile) => {
    if (!game?.board || !getTile(pos))
      return new Error("no tile at position");
    setGame(({...game2}) => {
      const currentTile = game2.board.tiles[pos.x][game2.board.height - 1 - pos.y];
      game2.board.tiles[pos.x][game2.board.height - 1 - pos.y] = {
        ...currentTile,
        ...tile
      };
      return game2;
    });
  }, [game, getTile]);
  const move = (move2) => {
    if (!room)
      return new Error("not currently connected to a room");
    setHighlightedTiles([]);
    console.log(`move at ${Date.now()}`);
    const fromTile = getTile(move2.from);
    console.log(fromTile);
    console.log(game?.nextMoves.find((m) => deepEqual(m, move2)));
    if (!fromTile?.piece || fromTile.piece.team !== game?.nextTeam || !game.nextMoves.find((m) => deepEqual(m, move2)))
      return new Error("invalid move");
    setLastMove(move2);
    setTile(move2.from, {piece: void 0});
    setTile(move2.to, {piece: fromTile.piece});
    ws.send({
      type: ClientMessageType.AttemptMove,
      move: move2,
      room
    });
  };
  const joinTeam = (team) => {
    if (!room)
      return new Error("not currently connected to a room");
    ws.send({
      type: ClientMessageType.JoinTeam,
      room,
      team
    });
  };
  const ready = () => {
    if (!room)
      return new Error("not currently connected to a room");
    console.log("rdy!");
    ws.send({
      type: ClientMessageType.RoomReady,
      room
    });
  };
  useEffect(() => {
    if (currentRoom)
      setRoom(currentRoom);
  }, [currentRoom]);
  useEffect(() => {
    if (!ws)
      return;
    const handleRoomMove = (e) => {
      const data = e.detail;
      if (data.room !== room)
        return;
      setHighlightedTiles([]);
      console.log(`move at ${Date.now()}`);
      const movedTile = getTile(data.move.from);
      if (!deepEqual(data.move, lastMove)) {
        if (!movedTile?.piece) {
          console.log("No Piece at:", data.move.from);
          console.log("No Piece at game:", game);
          return ws.send({
            type: ClientMessageType.SyncGame,
            room
          });
        }
        setTile(data.move.from, {piece: void 0});
        setTile(data.move.to, {piece: movedTile.piece});
      }
      setGame(({...game2}) => {
        console.log(game2);
        game2.nextMoves = data.nextMoves;
        game2.nextTeam = data.nextTeam;
        return game2;
      });
      setLastMove(void 0);
    };
    const handleSyncGame = (e) => {
      const data = e.detail;
      if (data.room !== (room ?? currentRoom))
        return;
      setGame(data.game);
      console.log("the board has been synced! ", data);
    };
    const handleRoomJoin = (e) => {
      const data = e.detail;
      if (data.room !== (room ?? currentRoom))
        return;
      if (!game?.players.includes(data.user) && data.user !== ws.user)
        setGame(({...game2}) => {
          game2.players.push(data.user);
          return game2;
        });
    };
    const filterStaleTeams = (game2, username = "") => game2.teams.map((team) => {
      const userInTeam = team.users.includes(username);
      const ready2 = !userInTeam && team.ready || userInTeam && team.users.length !== 1 && team.ready || void 0;
      return {
        ...team,
        users: team.users.filter((user) => user !== username),
        ready: ready2
      };
    });
    const handleRoomLeave = (e) => {
      const data = e.detail;
      if (data.room !== (room ?? currentRoom))
        return;
      setGame(({...game2}) => {
        game2.players = game2?.players.filter((player) => player !== data.user);
        game2.teams = filterStaleTeams(game2, data.user);
        return game2;
      });
    };
    const handleStartGame = (e) => {
      const data = e.detail;
      if (data.room !== (room ?? currentRoom))
        return;
      setGame(({...game2}) => ({...game2, running: true}));
      console.log("starting game", data);
    };
    const handleRoomReady = (e) => {
      const data = e.detail;
      if (data.room !== (room ?? currentRoom))
        return;
      setGame(({...game2}) => {
        const team = game2.teams.find((team2) => team2.name === data.team);
        if (team)
          team.ready = true;
        return game2;
      });
    };
    const handleJoinTeam = (e) => {
      const data = e.detail;
      if (data.room !== (room ?? currentRoom))
        return;
      setGame(({...game2}) => {
        game2.teams = filterStaleTeams(game2, data.user);
        game2.teams.find((team) => team.name === data.team)?.users.push(data.user);
        return game2;
      });
      console.log("someone joined a team: ", data);
    };
    const handleError = (e) => {
      const data = e.detail;
      console.log(data);
      console.log("error!");
      if (room)
        ws.send({type: ClientMessageType.SyncGame, room});
    };
    ws.on(ServerMessageType.StartGame, handleStartGame);
    ws.on(ServerMessageType.SyncGame, handleSyncGame);
    ws.on(ServerMessageType.JoinTeam, handleJoinTeam);
    ws.on(ServerMessageType.RoomMove, handleRoomMove);
    ws.on(ServerMessageType.RoomReady, handleRoomReady);
    ws.on(ServerMessageType.RoomJoin, handleRoomJoin);
    ws.on(ServerMessageType.RoomLeave, handleRoomLeave);
    ws.on(ServerMessageType.Error, handleError);
    return () => {
      ws.off(ServerMessageType.StartGame, handleStartGame);
      ws.off(ServerMessageType.SyncGame, handleSyncGame);
      ws.off(ServerMessageType.JoinTeam, handleJoinTeam);
      ws.off(ServerMessageType.RoomMove, handleRoomMove);
      ws.off(ServerMessageType.RoomReady, handleRoomReady);
      ws.off(ServerMessageType.RoomJoin, handleRoomJoin);
      ws.off(ServerMessageType.RoomLeave, handleRoomLeave);
      ws.off(ServerMessageType.Error, handleError);
    };
  }, [ws, room, game, currentRoom, getTile, setTile, lastMove]);
  return {
    ws,
    game,
    getTile,
    setTile,
    move,
    highlightedTiles,
    highlightTiles,
    unhighlightTiles,
    isHighlighted,
    joinTeam,
    ready
  };
};
export function isReady(api) {
  return Boolean(api.game);
}
